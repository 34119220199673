import React, { useEffect } from 'react';
import { Routes as ReactRoutes, Route, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ActiveSubscriptions from '../screens/analytics/ActiveSubscriptions';
import PremiumAnalytics from '../screens/analytics/PremiumAnalytics';
import Login from '../screens/Login';

// content screens
import Home from '../screens/content/Home';
import Deals from '../screens/content/Deals';
import Tool from '../screens/content/Tool';
import Todo from '../screens/content/Todo';
import Knowledge from '../screens/content/Knowledge';
import Notification from '../screens/content/Notification';
import NotificationCenter from '../screens/content/NotificationCenter';
import Popup from '../screens/content/Popup';
import Settings from '../screens/content/Settings';
import PremiumSettings from '../screens/content/PremiumSettings';

// analytics screens
import Users from '../screens/analytics/Users';
import Content from '../screens/analytics/Content';
import Company from '../screens/analytics/Company';

// other screens
import Forms from '../screens/Forms';
import AdminHelp from '../screens/AdminHelp';
import AppVersion from '../screens/AppVersion';
import UserDelete from '../screens/UserDelete';

import { API_TOKEN } from '../constants/auth';
import ProtectedRoute from './ProtectedRoute';
import { getMe } from '../redux/actions/auth';

const Routes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logerror = useSelector((state) => state.auth.logerror);

  const { role } = useSelector((state) => state.auth?.user);
  const isUser = !logerror && role ? role === 'user' : false;
  const { pathname } = useLocation();

  useEffect(() => {
    const initData = () => {
      dispatch(getMe());
    };

    initData();
  }, [dispatch]);

  useEffect(() => {
    if (logerror || isUser) {
      navigate('/login');
    }
  }, [isUser, logerror, navigate]);

  useEffect(() => {
    const checkRole = () => {
      const photographerEditorRoutes = ['/tools'];
      const knowledgeEditorRoutes = ['/knowledge'];
      const calendarEditorRoutes = ['/home'];

      if (role === 'photographer_editor' && !photographerEditorRoutes.includes(pathname))
        navigate(photographerEditorRoutes[0]);
      if (role === 'knowledge_editor' && !knowledgeEditorRoutes.includes(pathname))
        navigate(knowledgeEditorRoutes[0]);
      if (role === 'calendar_editor' && !calendarEditorRoutes.includes(pathname))
        navigate(calendarEditorRoutes[0]);
    };
    const token = sessionStorage.getItem(API_TOKEN);
    if (token) checkRole();
  }, [pathname, role, navigate]);

  return (
    <ReactRoutes>
      <Route path="*" element={<Navigate to="/home" />} />
      <Route path="/login" element={<Login />} />
      {/* Content screens */}
      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route
        path="/knowledge"
        element={
          <ProtectedRoute>
            <Knowledge excludedCategoryIds={['10', '14']} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tools"
        element={
          <ProtectedRoute>
            <Tool />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/todo"
        element={
          <ProtectedRoute>
            <Todo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notification"
        element={
          <ProtectedRoute>
            <Notification />
          </ProtectedRoute>
        }
      />
      <Route
        path="/notification-center"
        element={
          <ProtectedRoute>
            <NotificationCenter />
          </ProtectedRoute>
        }
      />
      <Route
        path="/popup"
        element={
          <ProtectedRoute>
            <Popup />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium-settings"
        element={
          <ProtectedRoute>
            <PremiumSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/deals"
        element={
          <ProtectedRoute>
            <Deals />
          </ProtectedRoute>
        }
      />
      <Route
        path="/useranalytics"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
      <Route
        path="/active-subscriptions"
        element={
          <ProtectedRoute>
            <ActiveSubscriptions />
          </ProtectedRoute>
        }
      />
      <Route
        path="/premium-analytics"
        element={
          <ProtectedRoute>
            <PremiumAnalytics />
          </ProtectedRoute>
        }
      />
      <Route
        path="/contentanalytics"
        element={
          <ProtectedRoute>
            <Content />
          </ProtectedRoute>
        }
      />
      <Route
        path="/companyanalytics"
        element={
          <ProtectedRoute>
            <Company />
          </ProtectedRoute>
        }
      />
      {/* Helper screens */}
      <Route
        path="/forms"
        element={
          <ProtectedRoute>
            <Forms />
          </ProtectedRoute>
        }
      />
      <Route
        path="/appversion"
        element={
          <ProtectedRoute>
            <AppVersion />
          </ProtectedRoute>
        }
      />
      <Route
        path="/help"
        element={
          <ProtectedRoute>
            <AdminHelp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/userdelete"
        element={
          <ProtectedRoute>
            <UserDelete />
          </ProtectedRoute>
        }
      />
    </ReactRoutes>
  );
};

export default Routes;
