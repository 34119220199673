/* eslint-disable react-hooks/exhaustive-deps */
import { addMonths, format, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTeladocReport } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import Dropdown from '../../Dropdown';
import Spinner from '../../Spinner';
import { CSVLink } from 'react-csv';

// Set start date as December 2024
const startDate = new Date(2024, 11);
// Get the previous month as the end date (since you want until previous month)
const endDate = subMonths(new Date(), 1);

function generateMonthLabels(start, end) {
  const labels = [];
  let currentDate = start;

  while (currentDate <= end) {
    const period = format(currentDate, 'yyyy-MM');
    labels.push({ label: period, value: period });
    currentDate = addMonths(currentDate, 1);
  }

  return labels;
}

const TeladocReport = () => {
  const dispatch = useDispatch();

  const allLoading = useSelector((state) => state.analytics.teladocUsersLoading);
  const users = useSelector((state) => state.analytics.teladocUsers);

  const months = generateMonthLabels(startDate, endDate);
  const [selectedDate, setSelectedDate] = useState(months[months.length - 1]);

  const search = () => {
    dispatch(getTeladocReport(selectedDate.value));
  };

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      dispatch(getTeladocReport(selectedDate.value));
    }
  }, []);

  const headers = [
    { label: 'User ID', key: 'userid' },
    { label: 'Short ID', key: 'shortId' },
    { label: 'Transaction date', key: 'transactionDate' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <Dropdown
        value={selectedDate.value}
        placeholder={'Select period...'}
        className={'w-1/2'}
        options={months}
        onChange={(value) => {
          setSelectedDate(value);
        }}
      />

      <div className="flex items-center justify-center w-full">
        <CustomButton type="button" className="text-white" onClick={search}>
          Search
        </CustomButton>

        {!allLoading && users.length !== 0 && (
          <CSVLink
            className="bg-orange text-white p-2 rounded"
            filename={`TeladocReport-${selectedDate.value}.csv`}
            data={users}
            headers={headers}
          >
            Download this table
          </CSVLink>
        )}
      </div>

      <div className="mx-12">
        {allLoading && <Spinner />}
        {!allLoading && users.length !== 0 && (
          <div>
            <table>
              <thead>
                <tr>
                  <th>User ID</th>
                  <th>Short ID</th>
                  <th>Transaction date</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.userid}</td>
                      <td>{item.shortId}</td>
                      <td>{item.transactionDate}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeladocReport;
