import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Layout from '../../components/layout/Layout';
import CustomButton from '../../components/CustomButton';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';
import HTMLEditor from '../../components/HTMLEditor';
import { getPremiumSettings, updateAllPremiumSettings } from '../../redux/actions/premiumSettings';
import PremiumTool from './PremiumTool';

const PremiumSettings = () => {
  const dispatch = useDispatch();
  const configs = useSelector((state) => state.premiumSettings.configs);
  const [premiumPopupContent, setPremiumPopupContent] = useState([]);
  const [currentPopupItem, setCurrentPopupItem] = useState(null);
  const [isAddingPopupItem, setIsAddingPopupItem] = useState(false);
  const [premiumKnowledgeHtmlContent, setPremiumKnowledgeHtmlContent] = useState('');

  useEffect(() => {
    dispatch(getPremiumSettings());
  }, [dispatch]);

  useEffect(() => {
    if (configs && Array.isArray(configs)) {
      const findConfigData = (key) => {
        const config = configs.find((item) => item.key === key);
        const data = config?.data;
        // Ensure we always return an array
        return Array.isArray(data) ? data : [];
      };

      setPremiumPopupContent(findConfigData('premiumPopup'));

      // Load premium knowledge content
      const knowledgeConfig = configs?.find((item) => item.key === 'premiumKnowledgeDescription');
      setPremiumKnowledgeHtmlContent(knowledgeConfig.data);
    }
  }, [configs]);

  const save = () => {
    const settings = {
      premiumPopup: premiumPopupContent || [],
      premiumKnowledgeDescription: premiumKnowledgeHtmlContent || '',
    };

    dispatch(updateAllPremiumSettings(settings));
  };

  const addPopupItem = () => {
    setCurrentPopupItem({ title: '', description: '' });
    setIsAddingPopupItem(true);
  };

  const savePopupItem = () => {
    if (currentPopupItem) {
      if (isAddingPopupItem) {
        setPremiumPopupContent([...premiumPopupContent, currentPopupItem]);
      } else {
        const updatedItems = premiumPopupContent.map((item) =>
          item === currentPopupItem._original ? currentPopupItem : item
        );
        setPremiumPopupContent(updatedItems);
      }
      setCurrentPopupItem(null);
      setIsAddingPopupItem(false);
    }
  };

  const editPopupItem = (item) => {
    setCurrentPopupItem({ ...item, _original: item });
    setIsAddingPopupItem(false);
  };

  const deletePopupItem = (itemToDelete) => {
    setPremiumPopupContent(premiumPopupContent.filter((item) => item !== itemToDelete));
  };

  const renderPopupEditor = () => {
    if (currentPopupItem) {
      return (
        <div className="bg-white p-4 rounded shadow">
          <TextField
            type="text"
            label="Title"
            value={currentPopupItem.title}
            onChange={(e) => setCurrentPopupItem({ ...currentPopupItem, title: e.target.value })}
          />
          <TextArea
            type="text"
            label="Description"
            value={currentPopupItem.description}
            onChange={(e) =>
              setCurrentPopupItem({ ...currentPopupItem, description: e.target.value })
            }
          />
          <div className="flex justify-end mt-4 space-x-2">
            <CustomButton onClick={() => setCurrentPopupItem(null)}>Cancel</CustomButton>
            <CustomButton onClick={savePopupItem}>Save</CustomButton>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <Layout active="premium-settings">
      <div className="mx-24">
        <div className="font-semibold text-lg text-center mt-10">Video course settings</div>
        <PremiumTool />
        <div className="font-semibold text-lg text-center mt-10">Video courses description</div>
        <div className="bg-gray-50 p-4 rounded">
          <HTMLEditor
            title="Content (html)"
            defaultValue={premiumKnowledgeHtmlContent}
            setContents={premiumKnowledgeHtmlContent}
            onChange={(content) => {
              setPremiumKnowledgeHtmlContent(content);
            }}
          />
        </div>
        <div className="font-semibold text-lg text-center mt-10">Dropdown items</div>
        <div className="bg-gray-50 p-4 rounded">
          {premiumPopupContent.map((item, index) => (
            <div
              key={index}
              className="flex items-center justify-between bg-white p-4 rounded mb-2"
            >
              <div className="flex-grow">
                <div className="font-medium">{item.title}</div>
                <div className="text-gray-600 whitespace-pre-wrap">{item.description}</div>
              </div>
              <div className="flex space-x-2 ml-4">
                <CustomButton onClick={() => editPopupItem(item)}>Edit</CustomButton>
                <CustomButton onClick={() => deletePopupItem(item)}>Delete</CustomButton>
              </div>
            </div>
          ))}
          <div className="mt-4">
            <CustomButton onClick={addPopupItem}>Add Item</CustomButton>
          </div>
          {renderPopupEditor()}
        </div>

        <div className="flex justify-center my-5">
          <CustomButton onClick={save}>Save</CustomButton>
        </div>
      </div>
    </Layout>
  );
};

export default PremiumSettings;
